<template>
    <el-button class="w-100 dashboard-button d-flex flex-column align-items-center" @click="onButtonClick()">
        <span class="d-flex flex-column">
            <i class="mb-1 font-large-2x" :class="icon" />
            <span class="text-wrap font-large-3">{{ text }}</span>
        </span>
    </el-button>
</template>

<script lang="ts">
import {Options, prop} from "vue-class-component";
import Vue from "@/extensions/Vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";

class Props {
    component = prop({
        required: true,
        type: String
    });
    icon = prop({
        required: true,
        type: String
    });
    text = prop({
        required: true,
        type: String
    });
}

@Options({
    name: "Dashboard",
    components: {
        DefaultLayout
    },
})
export default class Dashboard extends Vue.with(Props) {
    declare component: string;
    declare icon: string;
    declare text: string;

    async onButtonClick(): Promise<void> {
        await this.$router.push({name: this.component});
    }
}
</script>

<style scoped lang="scss">
.dashboard-button {}
</style>