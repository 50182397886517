<template>
    <default-layout>
        <div class="m-1">
            <div class="row gx-1 gy-1">
                <div class="col-6">
                    <dashboard-button icon="fa fa-hotel" text="Unit overview" component="Units" />
                </div>
                <div class="col-6">
                    <dashboard-button icon="fa fa-list" text="Job overview" component="Jobs" />
                </div>
                <div class="col-6">
                    <dashboard-button icon="fa fa-file-contract" text="Search contract" component="ContractSearch" />
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script lang="ts">
import {Options} from "vue-class-component";
import Vue from "@/extensions/Vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import DashboardButton from "@/components/controls/DashboardButton.vue";

@Options({
    name: "Dashboard",
    components: {
        DashboardButton,
        DefaultLayout
    },
})
export default class Dashboard extends Vue {

}
</script>

<style scoped>

</style>