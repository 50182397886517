
import {Options, prop} from "vue-class-component";
import Vue from "@/extensions/Vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";

class Props {
    component = prop({
        required: true,
        type: String
    });
    icon = prop({
        required: true,
        type: String
    });
    text = prop({
        required: true,
        type: String
    });
}

@Options({
    name: "Dashboard",
    components: {
        DefaultLayout
    },
})
export default class Dashboard extends Vue.with(Props) {
    declare component: string;
    declare icon: string;
    declare text: string;

    async onButtonClick(): Promise<void> {
        await this.$router.push({name: this.component});
    }
}
