
import {Options} from "vue-class-component";
import Vue from "@/extensions/Vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import DashboardButton from "@/components/controls/DashboardButton.vue";

@Options({
    name: "Dashboard",
    components: {
        DashboardButton,
        DefaultLayout
    },
})
export default class Dashboard extends Vue {

}
